.app {
  width: 100vw;
  height: 90vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app > main {
  max-height: 270px;
}

.app h1 {
  font-size: 3rem;
}

.profile {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile img {
  display: block;
  border-radius: 50%;
  border: 2px solid #fff;

  height: 160px;
  width: 160px;
}

.networking {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.networking > a {
  color: #fff;
  transition: color 0.3s;
}

.networking > a > svg {
  font-size: 2.5rem;
  color: inherit;
  transition: inherit;
}

.networking > a:hover > svg {
  color: #fac027;
}

.networking > a + a {
  margin-left: 10px;
}
